<template>
	<div class="home">

		<section class="page-head no-bg">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-8 my-5 text-center">
						<h1 class="display-5">Interesting stats</h1>
					</div>
				</div>
			</div>
		</section>


		<section class="bg-white py-6">
			<div class="container">

				<div v-if="stats" class="row text-center">
					<div class="col">
						<h3 class="display-6">Domains known</h3>
						<h1 class="mb-0">{{ stats.total.toLocaleString() }}</h1>
					</div>
					<div class="col">
						<h3 class="display-6">Domains we monitor</h3>
						<h1 class="mb-0">{{ stats.monitoring.toLocaleString() }}</h1>
					</div>
					<div class="col">
						<h3 class="display-6">Known <abbr title="Top Level Domains, ex: .com, .net, .app">TLDs</abbr></h3>
						<h1 class="mb-0"><router-link to="/tlds">{{ stats.tlds.toLocaleString() }}</router-link></h1>
					</div>
				</div>

			</div>
		</section>

		<section class="bg-light py-6">
			<div class="container">

				<h1 class="display-6 text-center">Detailed stats</h1>
				<p class="text-center mb-6">For the last 
					<select v-model="months">
						<option value="3">3 months</option>
						<option value="6">6 months</option>
						<option value="12">year</option>
					</select>
				</p>

				<h4>Domains in our database</h4>
				<stats-line-chart v-if="domainCounts.length" :data="domainCountsChart" :options="domainCountsChartOptions" :height="300" class="mb-6"></stats-line-chart>
				<div v-else class="spinner-border mb-6" role="status"></div>

				<h4>TLD WHOIS errors</h4>
				<p>Sometimes WHOIS requests return an error, here is the number of how many times this happened per TLD:</p>
				<stats-bar-chart v-if="tldWhoisErrors" :data="tldWhoisErrorsChart" :options="tldWhoisErrorsChartOptions" :height="600" class="mb-6"></stats-bar-chart>
				<div v-else class="spinner-border mb-6" role="status"></div>

			</div>
		</section>

	</div>
</template>

<script>
import domainsApi from '@/api.js'
import StatsBarChart from '@/components/StatsBarChart.vue'
import StatsLineChart from '@/components/StatsLineChart.vue'
import { format, parseISO } from 'date-fns'

export default {
	name: 'Extension',
	components: { StatsBarChart, StatsLineChart },
	data() {
		return {
			months: 6,
			stats: null,

			domainCounts: [],
			domainCountsChartOptions: {
				//response: true,
				maintainAspectRatio: false,
				scales: {
					yAxes: [{
						ticks: {
							//beginAtZero: true,
							callback: function (value) {
								return value > 1000 ? (value / 1000) + 'k' : value
							}
						}
					}]
				},
				tooltips: {
					callbacks: {
						label: function(tooltipItem) {
							return tooltipItem.yLabel.toLocaleString()
						}
					}
				}
			},
			domainCountsChart: {
				labels: [],
				dats: {},
				datasets: []
			},

			tldWhoisErrors: null,
			tldWhoisErrorsChartOptions: {
				//response: true,
				maintainAspectRatio: false,
				scales: {
					xAxes: [{
						stacked: true,
					}],
					yAxes: [{
						stacked: true
					}]
				},
			},
			tldWhoisErrorsChart: {
				labels: [],
				dats: {},
				datasets: []
			},
		}
	},
	created() {
		this.loadTldWhoisErrors()
		this.loadDomainCounts()

		domainsApi.get(`stats`).then(({ data }) => {
			this.stats = data
		})
	},
	methods: {
		loadDomainCounts() {
			this.domainCounts = []
			this.domainCountsChart = {
				labels: [],
				datasets: []
			}

			Promise.all([
				domainsApi.get(`stats/domains-total`, { params: { months: this.months } }),
				domainsApi.get(`stats/domains-monitoring`, { params: { months: this.months } }),
			]).then(([total, monitoring]) => {
				this.domainCountsChart.datasets.push({
					label: 'Total domains',
					data: [],
					fill: true,
					borderWidth: 2,
					backgroundColor: 'rgba(23, 62, 184, 0.02)',
					borderColor: 'rgba(23, 62, 184, 0.5)',
				}, {
					label: 'Monitored domains',
					data: [],
					fill: true,
					borderWidth: 2,
					backgroundColor: 'rgba(40, 167, 69, 0.2)',
					borderColor: 'rgba(40, 167, 69, 0.5)',
				})

				total.data.forEach(stat => {
					this.domainCountsChart.labels.push(format(parseISO(stat.date), 'MMM yyyy'))
					this.domainCountsChart.datasets[0].data.push(stat.data)
				})

				monitoring.data.forEach(stat => {
					this.domainCountsChart.datasets[1].data.push(stat.data)
				})

				this.domainCounts.push(total.data)
			})

		},
		loadTldWhoisErrors() {
			this.tldWhoisErrors = null
			this.tldWhoisErrorsChart = {
				labels: [],
				dats: {},
				datasets: []
			}

			domainsApi.get(`stats/tld-whois-errors`, { params: { months: this.months } }).then(({ data }) => {
				const allTlds = []

				// create chart labels & prepare TLDs
				data.forEach(stat => {
					this.tldWhoisErrorsChart.labels.push(format(parseISO(stat.date), 'MMM yyyy'))

					stat.data.forEach(dat => {
						if (!allTlds.includes(dat.tld)) {
							allTlds.push(dat.tld)
							this.tldWhoisErrorsChart.dats[dat.tld] = {
								label: dat.tld,
								data: [],
								backgroundColor: `#${Math.floor(Math.random()*16777215).toString(16)}`,
							}
						}
					})
				})

				// fill bars with numbers
				data.forEach(stat => {
					allTlds.forEach(tld => {
						const hasData = stat.data.find(d => d.tld === tld)
						this.tldWhoisErrorsChart.dats[tld].data.push(hasData ? hasData.total : 0)
					})
				})

				this.tldWhoisErrorsChart.datasets = Object.values(this.tldWhoisErrorsChart.dats)
				this.tldWhoisErrors = data
			}, error => {
				alert(`Error loading stats: ${error}`)
			})
		},
	},
	watch: {
		months() {
			this.loadTldWhoisErrors()
			this.loadDomainCounts()
		}
	}
}
</script>
